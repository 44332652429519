<template>
  <div class="col-12 col-lg-6 c g">
    <br><br><br>
    <div class="col-12 text-center">
        <img :src="require('@/assets/images/banner.png')" style="width: 300px">
        <h3>masrufi.brmja.com</h3>
        <br>
    </div>
    <div class="card card-body">
        <div class="col-12" v-if="message.type">
            <div class="alert alert-danger text-center g" v-if="message.type == 'error'">
                <h1 class="text-danger g text-dark" v-html="message.message"></h1>
            </div>
            <div class="alert alert-success text-center g" v-if="message.type == 'success'">
                <h1 class="text-success g text-dark" v-html="message.message"></h1>
            </div>
        </div>
        <input type="text" class="form-control form-control-lg scanner" v-if="!loading" v-model="barcode" style=" border-radius: 0px; text-align: center;" placeholder="ابدأ الآن باستخدام الباركود...">
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            barcode: "",
            last_barcode: "",
            loading: false,
            message: "",
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            timeout: null
        }
    },
    created(){
        var g = this;
        setTimeout(() => {
            setInterval(() => {
                $('.scanner').focus();
            });
            setInterval(() => {
                if(g.barcode != "" && g.barcode != g.last_barcode){
                    setTimeout(() => {
                        g.last_barcode = g.barcode;
                        //
                        g.checkBarcode(g.barcode?.trim())
                        //
                        g.barcode = ""
                        g.last_barcode = g.barcode;
                    }, 100);
                }
            }, 200);
        }, 1000);
    },
    methods: {
        checkBarcode(number){
            var g = this;
            if(g.timeout){
                clearTimeout(g.timeout)
            }
            g.loading = true;
            $.post(api + '/user/students/barcode', {
                jwt: this.jwt,
                number: number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    g.message = {
                        type: 'error',
                        message: r.response
                    }
                }else{
                    g.message = {
                        type: 'success',
                        message: r.response.message
                    }
                    var win = window.open ("masrufi.brmja.com", "نظام مصروفي | masrufi.brmja.com","menubar=0,resizable=0,width=400,height=600");
                    win.document.write(`
                    <html dir='rtl'>
                        <head>
                            <link rel="preconnect" href="https://fonts.googleapis.com">
                            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                            <link href="https://fonts.googleapis.com/css2?family=Vazirmatn&display=swap" rel="stylesheet">
                            <Style>
                                html{
                                    margin:0;padding:0;
                                }
                                *{
                                    font-family: 'Vazirmatn', sans-serif;
                                },
                                table, td,th,tr{
                                    padding: 0px;
                                }
                            </Style>
                        </head>
                        <body>
                            <center>
                                <br>
                                <h1>${r.response.amount} ريال</h1>
                            </center>
                            <br>
                            <table style="width:100%">
                                <tbody>
                                    <tr>
                                        <td>التاريخ</td>
                                        <td><span dir='ltr'>${r.response.time.replace(":" + r.response.time.split(":")[r.response.time.split(":").length - 1], "")}</span></td>
                                    </tr>
                                    <tr>
                                        <td>الاسم</td>
                                        <td>${r.response.name}</td>
                                    </tr>
                                    <tr>
                                        <td>رقم الهوية</td>
                                        <td>${r.response.number}</td>
                                    </tr>
                                    <tr>
                                        <td>ملاحظات</td>
                                        <td>${r.response.notes}</td>
                                    </tr>
                                </tbody>        
                            </table><br>
                            <center style='color: #999'><small>#${r.response.id}</small><br>نظام مصروفي &nbsp;&mdash;&nbsp; masrufi.brmja.com</center>
                            <hr>
                        </body>
                    </html>
                    `)
                    win.print()
                    setTimeout(() => {
                        win.close()
                    }, 3000);
                }
                g.timeout = setTimeout(() => {
                    g.message = {}
                }, 10000);
            }).fail(function(){
                g.loading = false
                g.message = {
                    type: 'error',
                    message: "حدث مشكلة في الاتصال.. برجاء التأكد من اتصال الانترنت."
                }
                g.timeout = setTimeout(() => {
                    g.message = {}
                }, 10000);
            })
        }
    }
}
</script>

<style>

</style>